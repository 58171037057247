window.ENV_CONFIG = {
  baseUrl: process.env.API_URL,
  feedbackDuration: 2000,
  coalaVersion: process.env.VERSION,
  coalaBuild: process.env.BUILD_NUM,
  coalaBranch: process.env.BRANCH_NAME,
  coalaBuildTime: process.env.BUILD_TIME,
  defaultTableItemsPerPage: 10,
  autoLogoutMinutes: 20,
  maintenanceMode: false,
  azureSubscriptionId: '0501f6bb-6cba-4191-a61a-c041c986b8e4',
  monitoringCenter: process.env.MONITORING_CENTER,
  defaultNationId: process.env.DEFAULT_NATION
};
